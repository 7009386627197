<template>
  <div class="login" clearfix>
    <div class="login-wrap">
      <el-card class="box-card">
        <!-- <el-row type="flex" justify="center"> -->
        <el-form ref="loginForm" :model="user" status-icon>
          <h3 style="color: black">Chat-GPT</h3>
          <el-form-item prop="email" label="邮箱">
            <el-input
              v-model="user.email"
              placeholder="请输入邮箱"
              prefix-icon
            ></el-input>
          </el-form-item>
          <el-form-item id="password" prop="password" label="密码">
            <el-input
              v-model="user.password"
              show-password
              placeholder="请输入密码"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <div class="linkBox">
              <div class="linkBox_a">
                <router-link to="/forget">找回密码</router-link>
              </div>
              <div class="linkBox_B">
                <router-link to="/register">注册账号</router-link>
              </div>
            </div>
          </el-form-item>
          <el-form-item>
            <div>
              <el-button type="primary" @click="doLogin()">登 录</el-button>
            </div>
          </el-form-item>
        </el-form>
        <!-- </el-row> -->
      </el-card>
    </div>
  </div>
</template>

<script>
import { setToken } from "@/utils/auth";
import { login } from "@/api/user";

export default {
  name: "login",
  data() {
    return {
      user: {
        email: "",
        password: "",
      },
    };
  },
  created() {},
  methods: {
    doLogin() {
      if (!this.user.email) {
        this.$message.error("请输入用户名！");
        return;
      } else if (!this.user.password) {
        this.$message.error("请输入密码！");
        return;
      } else {
        let model = {
          email: this.user.email,
          password: this.user.password,
        };
        login(model).then((res) => {
          setToken(res.value);
          this.$router.push({ path: "/" });
        });
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.linkBox {
  display: flex;
  width: 100%;
  justify-content: space-between;
  linkBox_a {
    justify-items: start;
  }
  linkBox_b {
    justify-self: end;
  }
}
.login {
  width: 100%;
  height: 100vh;
  // background: url("../assets/images/bg.jpg") no-repeat;
  //background: url("../assets/images/bg.jpg") no-repeat;
  background-size: cover;
  overflow: hidden;
}

.login-wrap {
  //background: url("../assets/images/ll.jpg") no-repeat;
  //background: #ffffff ;
  background-color: rgb(249 250 251 1);
  border-radius: 10px;
  background-size: cover;
  width: 550px;
  // height: 400px;
  margin: 200px auto;
  overflow: hidden;
  padding-top: 10px;
  line-height: 40px;
}

#password {
  margin-bottom: 5px;
}

h3 {
  color: #0babeab8;
  font-size: 24px;
}

hr {
  background-color: #444;
  margin: 20px auto;
}

a {
  text-decoration: none;
  color: #00c4ff;
  font-size: 15px;
}

a:hover {
  color: coral;
}

.el-button {
  width: 100%;
  margin-left: 0px;
}
</style>
